import { Renderer2 } from '@angular/core';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, Subscription, filter } from 'rxjs';
import { readFileAsync } from '@et/utils';
import {
  PDFDocumentProxy,
  PDFPageProxy,
  RenderTask,
  getDocument,
} from 'pdfjs-dist';
import { SidebarPage } from './document-sidebar.component';
import { DocumentMarkup } from '@et/typings';

export class DocumentDataSource extends DataSource<SidebarPage | undefined> {
  private _length = 0;
  private _pageSize = 5;
  private _cachedData = Array.from<SidebarPage>({ length: this._length });
  private _loadedPages = new Set<number>();
  private readonly _dataStream$ = new BehaviorSubject<
    (SidebarPage | undefined)[]
  >([]);
  private readonly _subscription = new Subscription();
  private _PDFDocumentProxy!: PDFDocumentProxy;
  private _renderer: Renderer2;
  private _renderTask!: RenderTask | null;
  private _isLoaded = false;
  private _documentMarkup: DocumentMarkup | null = null;
  constructor(
    file: File | string,
    renderer: Renderer2,
    documentMarkup: DocumentMarkup | null,
  ) {
    super();
    this._renderer = renderer;
    this.init(file, documentMarkup);
  }

  private async init(
    pdf: File | string,
    documentMarkup: DocumentMarkup | null,
  ) {
    let fileread: string;
    if (pdf instanceof File) {
      fileread = (await readFileAsync(pdf)) as string;
    } else {
      fileread = pdf;
    }
    this._PDFDocumentProxy = await getDocument(fileread as string).promise;
    this._documentMarkup = documentMarkup;
    this._length = this._PDFDocumentProxy.numPages;
    this._cachedData = Array.from<SidebarPage>({ length: this._length });
    this._isLoaded = true;
    this._loadPage(1);
  }

  /**
   * It returns an observable of the data array to be rendered by the scroll container.
   * @returns Observable<(SidebarPage | undefined)[]>
   */
  connect(
    collectionViewer: CollectionViewer,
  ): Observable<(SidebarPage | undefined)[]> {
    this._subscription.add(
      collectionViewer.viewChange
        .pipe(filter(() => this._isLoaded))
        .subscribe((range) => {
          const startPage = this._getPageForIndex(range.start);
          const endPage = this._getPageForIndex(range.end - 1);
          for (let i = startPage; i <= endPage; i++) {
            this._loadPage(i);
          }
        }),
    );
    return this._dataStream$;
  }

  /**
   * It disconnects data source from collectionViewer
   */
  disconnect(): void {
    this._subscription.unsubscribe();
    this._PDFDocumentProxy?.destroy();
  }

  /**
   * It takes a PDF page index and returns the scroll page number
   * @param index - index of the PDF page
   * @returns
   */
  private _getPageForIndex(index: number): number {
    return Math.floor(index / this._pageSize) + 1;
  }

  /**
   * It takes a page number and returns an array of PDF page numbers to be rendered
   * @param page - page number
   * @returns number[]
   */
  private _getPageImagesForPage(page: number): number[] {
    const pages: number[] = [];
    const offset = (page - 1) * this._pageSize;
    for (let i = 1; i <= this._pageSize; i++) {
      if (offset + i > this._length) {
        break;
      }
      pages.push(offset + i);
    }
    return pages;
  }

  /**
   * It takes a page number and loads the PDF pages to be rendered
   * @param page - page number
   */
  private async _loadPage(page: number) {
    if (this._loadedPages.has(page)) {
      return;
    }
    this._loadedPages.add(page);

    const loadPageImgs = this._getPageImagesForPage(page);

    for (const pageNumber of loadPageImgs) {
      const img = await this.renderPage(pageNumber, 150);
      const complete =
        this._documentMarkup &&
        this.pageIsComplete(this._documentMarkup, pageNumber);
      const hasSignatureRequired =
        this._documentMarkup &&
        this.hasSignatureRequired(this._documentMarkup, pageNumber);
      const hasStampRequired =
        this._documentMarkup &&
        this.hasStampRequired(this._documentMarkup, pageNumber);
      this._cachedData[pageNumber - 1] = {
        pageNumber,
        img,
        complete,
        hasSignatureRequired,
        hasStampRequired,
      };
      this._dataStream$.next(this._cachedData);
    }
  }

  /**
   * It takes page number, page width and returns a promise that resolves to a data string image.
   * @param pageNumber - page number
   * @param width - page width
   * @returns - Promise<string>
   */
  async renderPage(pageNumber: number, width: number): Promise<string> {
    if (!this._PDFDocumentProxy) {
      throw new Error('PDF file is not loaded');
    }
    const page = await this._PDFDocumentProxy.getPage(pageNumber);
    const vp = page.getViewport({ scale: 1 });
    const heightWidthRatio = vp.height / vp.width;
    const canv = await this.createCanvas(page);
    canv.height = width * heightWidthRatio;
    canv.width = width;
    const scale = Math.min(canv.width / vp.width, canv.height / vp.height);
    if (this._renderTask) {
      this._renderTask.cancel();
    }
    this._renderTask = page.render({
      canvasContext: canv.getContext('2d') as CanvasRenderingContext2D,
      viewport: page.getViewport({ scale }),
    });
    return this._renderTask.promise
      .then(() => canv.toDataURL('image/png'))
      .catch((error: any) => {
        this._renderTask = null;

        if (error.name === 'RenderingCancelledException') {
          return this.renderPage(pageNumber, width);
        }
        return error;
      });
  }

  /**
   * It takes a page from the PDF document and renders it to a canvas element
   * @param page - The page to render.
   * @returns A promise.
   */
  private async createCanvas(page: PDFPageProxy): Promise<HTMLCanvasElement> {
    const vp = page.getViewport({ scale: 1 });
    const canvas = this._renderer.createElement('canvas');
    const scalesize = 1;
    canvas.width = vp.width * scalesize;
    canvas.height = vp.height * scalesize;

    const scale = Math.min(canvas.width / vp.width, canvas.height / vp.height);
    return await page
      .render({
        canvasContext: canvas.getContext('2d'),
        viewport: page.getViewport({ scale: scale }),
      })
      .promise.then(() => canvas);
  }

  /**
   * It checks if the page has a stamp required
   * @param documentMarkup - DocumentMarkup
   * @param pageNumber - page number
   * @returns boolean
   */
  private hasStampRequired(documentMarkup: DocumentMarkup, pageNumber: number) {
    return (
      documentMarkup.data.pages.filter((page) => page.page === pageNumber)[0]
        .notarize === true &&
      documentMarkup.data.pages.filter((page) => page.page === pageNumber)[0]
        .stamped === false
    );
  }

  /**
   * It checks if the page is complete
   * @param documentMarkup - DocumentMarkup
   * @param pageNumber - page number
   * @returns boolean
   */
  private pageIsComplete(documentMarkup: DocumentMarkup, pageNumber: number) {
    const pageHasTabsRequired = documentMarkup.data.pages
      .filter((page) => page.page === pageNumber)[0]
      .tabs.some((tab) => tab.required === true);
    if (!pageHasTabsRequired) {
      return false;
    }
    return (
      !this.hasSignatureRequired(documentMarkup, pageNumber) &&
      !this.hasStampRequired(documentMarkup, pageNumber)
    );
  }

  /**
   * It checks if the page has a signature required
   * @param documentMarkup - DocumentMarkup
   * @param pageNumber - page number
   * @returns boolean
   */
  private hasSignatureRequired(
    documentMarkup: DocumentMarkup,
    pageNumber: number,
  ) {
    if (
      documentMarkup.data.pages.filter((page) => page.page === pageNumber)[0]
        .tabs.length === 0
    ) {
      return false;
    }
    return documentMarkup.data.pages
      .filter((page) => page.page === pageNumber)
      .every((page) =>
        page.tabs.every((tab) => tab.required === true && tab.signed === false),
      );
  }
}
